(function($) {

	var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');

	/**
	 * Make domains sortable.
	 * @return {void}
	 */
	$(function() {
		$('.label-choice').on('click', function () {
			$('#submitChoice').removeClass('hidden');
		});
		$(".domain-list-draggables img, .domain-list-target img").draggable({
			revert: "invalid"
		});
		$(".domain-list-draggables li").droppable({
			accept: function(el) {
				return (
					el.is(".domain-list-target img") &&
					$(this).not(":has(img)")
				);
			},
			drop: function(event, ui) {
				ui.draggable
					.css({top: "auto", left: "auto"})
					.appendTo($(this));
				// we retreive the domain and session id to pass to the backend
				var domainID = $(ui.draggable.context).data('domain-id');
				var sessionID = $(ui.draggable.context).data('session-id');
				var status = 2;
				setImportantDomainStatus(domainID, sessionID, status);

				// If all domains are sorted in important or unimportant,
				// Schow the next button.
				if ($('li.ui-droppable').length === ($('li.ui-droppable').length - $('li.ui-droppable img').length)) {
					$('#domain-next-button').addClass('show');
				} else {
					$('#domain-next-button').removeClass('show');
				}
			}
		});

		// If all domains are sorted in important or unimportant on page load,
		// Schow the next button.
		if ($('li.ui-droppable img').length === 0) {
			$('#domain-next-button').addClass('show');
		}

		$(".single-domain img, .important-domain img, .unimportant-domain img").on("mousedown touchstart", function() {
			$(".domain-name h3").text($(this).attr("alt"));
		});

		$('a.button--info').on('click', function(){
			$('.single-domain img').first().animate({
				'margin-left' : $(window).width() / 2 - 136,
				'margin-top' : 50,
			}, 1500, 'swing', function(){
				$('.single-domain img').first().animate({
					'margin-left' : '',
					'margin-top' : '',
				}, 800, 'swing')
			});
			//$('.single-domain img').first().effect("transfer", {to: '#effect-target', className: 'ui-effects-transfer'}, 2000);
		});

		$(".domain-list-target").droppable({
			accept: function() {
				return $(this).find("li").not(":has(img)").length > 0;
			},
			// the domain is dropped in the important area
			drop: function(event, ui) {
				var emptyDomains = $(this).find("li").not(":has(img)");
				if (emptyDomains.length > 0) {
					var nextDomain = emptyDomains.first();
					ui.draggable
						.css({top: "auto", left: "auto"})
						.appendTo(nextDomain);
				} else {
					return false;
				}
				// we retreive the domain and session id to pass to the backend
				var domainID = $(ui.draggable.context).data('domain-id');
				var sessionID = $(ui.draggable.context).data('session-id');
				if($(this).hasClass('important')){
					var status = 1;
				}else{
					var status = 0;
				}

				setImportantDomainStatus(domainID, sessionID, status);

				// If all domains are sorted in important or unimportant, 
				// Schow the next button.
				if ($('li.ui-droppable').length === ($('li.ui-droppable').length - $('li.ui-droppable img').length)) {
					$('#domain-next-button').addClass('show');
				} else {
					$('#domain-next-button').removeClass('show');
				}
			}
		});
	});

	$(function() {
		$.fn.makeCircle = function(pos) {
			circle_radius = pos;
			$links = this;
			total_links = $links.size();
			$links.each(function(index) {
				$(this).attr('data-index',index);
				animateCircle($(this), 1);
			});

			function animateCircle($link, expansion_scale) {
				index = $link.attr('data-index');
				radians = 2*Math.PI*(index/total_links);
				x = -(Math.sin(radians)*circle_radius*expansion_scale);
				y = -(Math.cos(radians)*circle_radius*expansion_scale);
				$link.animate({
					top: pos+x+'px',
					left: pos+y+'px'
				}, 200);
			}
		};

		if ($(window).width() > 667) {
			$('.important-domain').makeCircle(101);
			$('.unimportant-domain').makeCircle(101);
		}
	});

	/**
	 * Select a domain and add or subtract points with a + or -. 
	 * @return {void}
	 */
	$(function() {
		var add            = $(".points-popup .add"),
			subtract       = $(".points-popup .subtract"),
			points         = $(".points-popup .points"),
			clickRemainder = $(".points.click .remainder"),
			domainClick    = $(".domain-row-click li");

		// Add points to domain and subtract from remainder.
		add.on("click touchstart", function(e) {
			e.preventDefault();
			if (clickRemainder.text() > 0) {
				points.text(parseInt(points.text()) + 1);
				clickRemainder.text(parseInt(clickRemainder.text()) - 1);
			}
		});

		// Subtract points from domain and add to remainder.
		subtract.on("click touchstart", function(e) {
			e.preventDefault();
			if (points.text() > 0) {
				points.text(parseInt(points.text()) - 1);
				clickRemainder.text(parseInt(clickRemainder.text()) + 1);
			}
		});

		// Select a domain on click and change the title.
		domainClick.on("click touchstart", function() {
			domainClick.removeClass("selected");
			$(this).addClass("selected");
			$(".points-popup h2").text($(this).children("img").attr("alt"));
		});
	});

	/**
	 * Press a domain to swipe up or down. This way you can add 
	 * and subtract points from the remaining points.
	 * @return {void}
	 */
	$(function() {
		var swipeRemainder = $(".points.swipe .remainder"),
			domainSwipe    = $(".domain-row-swipe li span"),
			swipePoints    = $(".domain-row-swipe li .points"),
			isMouseDown    = false,
			startSwipe;

		// Swipe a domain up/down to give points.
		domainSwipe.on({

			// On touchstart or mousedown check what the starting point is so you can
			// add and subtract height (margin) from the domain.
			// Also change the title (H2) to the domain you've selected.
			'touchstart mousedown': function(e) {
				if (e.originalEvent.touches && e.originalEvent.touches.length > 0) {
					startSwipe = e.originalEvent.touches[0].clientY;
				} else {
					startSwipe = e.clientY;
				}
				$(".points-popup h2").text($(this).attr("alt"));
				isMouseDown = true;
			},

			// When moving the mouse/finger, run the move function.
			'touchmove mousemove': function(e) {
				if (isMouseDown) {
					var movePos;

					if (e.originalEvent.touches && e.originalEvent.touches.length > 0) {
						movePos = e.originalEvent.touches[0].clientY;
						move.call(this, movePos);
					} else {
						movePos = e.clientY;
						move.call(this, movePos);
					}
				}
			},

			// Set the endMargin when finished moving for further use.
			'touchend mouseup': function(e) {
				$(this).data("endMargin", Number($(this).css("margin-bottom").replace("px", "")));
			}
		});

		// Set mousedown to false when you stop the click anywhere on the page.
		$(this).on("touchend mouseup", function() {
			isMouseDown = false;
		})

		/**
		 * Call this move function when you want to move the domains
		 * up or down to give points.
		 * @return {void}
		 */
		function move(movePos) {
			var endMargin    = $(this).data("endMargin") || 0,
				curPos       = endMargin + (startSwipe - movePos),
				interval     = curPos - ( curPos % 30 ),
				prevInterval = Number($(this).css('margin-bottom').replace('px', ''));

			// Check all the points that are given and put them in an array.
			var arrayNumbers = swipePoints.map(function(el) {
				return $(this).text();
			}).get().map(function(value) {
				return Number(value);
			});

			// Add up all the numbers in the array.
			total = arrayNumbers.reduce(function(prev, cur) {
				return prev + cur;
			});

			// Check the interval and points, if they match the if statement,
			// then execute the rest of this function.
			if ((interval >= 0) && 
				(interval <= 300) &&
				(total < 20 || interval < prevInterval)) {

				// Add or subtract bottom margins to the domain when moving.
				$(this).css({
					"margin-bottom": interval
				});

				// Change the ammount of points when moving the domain
				// between 0 and 10.
				if ((interval / 30) >= 1 && interval / 30 <= 9) {
					$(this).prev(".points").text(String(interval / 30));
				} else if (interval / 30 >= 9){
					$(this).prev(".points").text("10");
				} else {
					$(this).prev(".points").text("0");
				}

				// If the ammount of points is "0", make it white. If not,
				// make it green.
				if ($(this).prev(".points").text() === "0") {
					$(this).prev(".points").css("color", "white");
				} else {
					$(this).prev(".points").css("color", "#85C9C2");
				}
			}

			// Check how many points remain to give to the domains.
			swipeRemainder.text(20 - total);
		}
	});

	function setImportantDomainStatus(domainID, sessionID, status) {
			// we send the data of this domain to the backend for changing
			// the important state
			$.ajax({
				type: "POST",
				url: config.baseUrl + '/important-domains/set-important-domain',
				data: {
					'domainID': domainID,
					'sessionID': sessionID,
					'status' : status,
					_token: CSRF_TOKEN
				},
				dataType: 'JSON',
				success: function(data) {
					console.log(data);
				}
			});
		}
}(jQuery));