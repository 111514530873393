(function($) {

    $(function() {
        var InfoWindow = function($el) {
            this.$el = $el;
            InfoWindow.all.push($el);
        };

        InfoWindow.all = [];

        InfoWindow.prototype.open = function() {
            $.each(InfoWindow.all, function(key, $el) {
                $el.removeClass('open');
            });
            this.$el.addClass('open');
        };

        InfoWindow.prototype.close = function() {
            this.$el.removeClass('open');
        };

        InfoWindow.prototype.toggle = function() {
            if (!this.$el.hasClass('open')) {
                this.open();
            } else {
                this.close();
            }
        };

        $.fn.infoWindow = function() {
            return new InfoWindow($(this));
        };

        /**
         * Open and close the navigation in header.
         * @return {void}
         */
        var infoButton = $('.button--info'),
            closeButton = $('.button--close'),
            feedbackButton = $('.button--feedback'),
            infoWindow = $('.info-window.tips').infoWindow(),
            feedbackWindow = $('.info-window.feedback').infoWindow(),
            logoutWindow = $('.info-window.logout').infoWindow(),
            closeWindow = $('.info-window.close').infoWindow();

        // Toggle on click to open and close the navigation.
        infoButton.on('click', function() {
            infoWindow.toggle();
        });

        closeButton.on('click', function() {
            closeWindow.toggle();
        });

        feedbackButton.on('click', function() {
            feedbackWindow.toggle();
            if ($('.info-window.feedback').hasClass('open')) {
                feedbackButton.html(
                    '<div class="icon">' +
                    '<img src="' + config.baseUrl + '/img/close.svg" alt="">' +
                    '</div>' +
                    'Sluiten'
                )
            } else {
                feedbackButton.html(
                    '<div class="icon">' +
                    '<img src="' + config.baseUrl + '/img/feedback.svg" alt="">' +
                    '</div>' +
                    'Feedback'
                )
            }
        });

        $('a.logout').on('click', function() {
            logoutWindow.open();
            $('nav').removeClass('open');
        });

        $('a.dont-logout').on('click', function() {
            logoutWindow.close();
        });

        $('a.dont-close').on('click', function() {
            closeWindow.close();
        });
    });

})(jQuery);