(function($) {
	$.fn.scrollSelect = function() {
	  	var itemNum = $(this).children().children().length,
	  		itemHeight = $(this).children().children().height(),
	  		itemWrapHeight = (itemNum * itemHeight) - itemHeight,
	  		selectFade = $(this).prev();

		selectFade.on('mousewheel touchstart touchmove', function(event) {
	  		var select = $(this).next();

	  		var delta = event.originalEvent.wheelDelta,
	  			itemWrapMargin = parseInt(select.children().css('marginTop'), 10);

			if (delta < 0 && itemWrapMargin > -itemWrapHeight) {
				select.children().css('marginTop', '-=' + itemHeight);
			} 

			if (delta > 0 && itemWrapMargin < 0) {
				select.children().css('marginTop', '+=' + itemHeight);
			}
		});
	};

	$(function() {
		$('.select.how-many').scrollSelect();
		$('.select.dmy').scrollSelect();
		$('.select.continents').scrollSelect();
		$('.select.countries').scrollSelect();
	})

	$(function() {
		$("input:radio").on('click', function() {
			if ($(this).is(":checked")) {
				$('input:radio').removeClass('selected');
				$(this).addClass('selected');
			}
		});
	});

	if ($("input:radio").is(":checked")) {
		$('.input:radio').removeClass('selected');
		this.addClass('selected');
	}

})(jQuery);
