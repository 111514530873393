(function($) {

	$(function() {
		/**
		 * Open and close the navigation in header.
    	 * @return {void}
		 */
		var user = $('.name-wrapper'),
			nav = $('nav'),
			navUl = $('nav .ul-wrapper'),
			arrow = $('.menu-arrows'),
			header = $('.inner-header');

		setTimeout(function() {
			if (nav.length) {
				setNavPosition();

				// Set the position on resizing the window.
				$(window).on('resize', function() {
					setNavPosition();
				});

				// Toggle on click to open and close the navigation.
				user.on('click', function() {
					header.toggleClass('nav-open');
					nav.toggleClass('open');
				});

				$('body > section').on('click', closeNavigation);
				$('a').on('click', closeNavigation);
			}
	    }, 500);

		/**
		 * Closes nav menu.
		 * @return {void}
		 */
	    var closeNavigation = function() {
			nav.removeClass('open');
			header.removeClass('nav-open');
	    };

		// Disable open the nav when a info window is open.
		nav.on('click', function() {
			if ($('.info-window.open').length) {
				nav.removeClass('open');
				header.removeClass('nav-open');
			}
		});

		// Set the position of the navigation in de middle of the arrow.
	    var setNavPosition = function() {
	    	if ($(window).width() < 720) {
				navUl.css({
					'left' : ($(window).width() - navUl.width()) / 2,
					'right': ''
				});
			} else if (
				(($(window).width() / 2) + 220) > arrow.offset().left &&
				$(window).width() > 720
			) {
				navUl.css({
					'left' : '',
					'right': '25px'
				});
			} else {
	    		navUl.css({
					'left' : arrow.offset().left - ((navUl.width() - arrow.width()) / 2),
					'right': ''
				});
	    	}
		}
	});

})(jQuery);