(function($) {
    $(function() {
        var form = $('#feedback-form');

        form.on('submit', function(e) {
            e.preventDefault();
            var text = $('#feedback-form textarea').val().trim(),
                csrf = $('#csrf').val(),
                alert = $('#feedback-form .alert');

            if (text.length > 0) {
                $.post(config.baseUrl + '/feedback', {
                    _token: csrf,
                    text: text,
                    page: document.location.href
                }).then(function(result) {
                    alert.text('Feedback verstuurd.')
                }, function(jqXhr) {
                    alert.text('Er is iets misgegaan met het versturen van de feedback.')
                });
            } else {
                alert.text('Vul je feedback in voordat je het verstuurd.')
            }
        });
    });
})(jQuery);