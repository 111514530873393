(function($) {
    $(function() {

        var CSRF_TOKEN = $('meta[name="csrf-token"]').attr('content');

        // if the question already has an answer, make it possible to go to the next page
        if($('.question.hasAnswer').length) {
            $('.button--question.next-question').addClass('show');
        }

        /**
         * Select an answer on click and give it an selected state.
         * @return {void}
         */
        $('.dichotomous-wrapper a').on('click touchstart touchend touchmove', function() {
            select($(this));
            showButtons($(this));
        });

        $('.multiplechoice-wrapper a').on('click touchstart touchend touchmove', function() {
            select($(this));
            showButtons($(this));
        });

        $('.scale-wrapper a').on('click touchstart touchend touchmove', function() {
            select($(this));
            showButtons($(this));
        });

        $('.core--wrapper input').on('change touchstart touchend touchmove', function() {
            showButtons($(this));
        })

        // Function to add selected class to choosen answer and
        // remove that class on other answers.
        var select = function(el) {
            if (el.hasClass('selected')) {
                el.removeClass('selected');
            } else {
                el.parent().find('.selected').removeClass('selected');
                el.addClass('selected');
            }
        }

        // If an answer has been choosen, show buttons to continue
        // or go back to another question.
        var showButtons = function(el) {
            if (el.hasClass('selected') || el.prop('checked')) {
                $('.button--question.next-question').addClass('show');
            } else {
                $('.button--question.next-question').removeClass('show');
            }
        }


        //answers

        //check if a question is present at the page
        if($('.question').length) {
            // get the question type and question id
            var questionType = $('.question').data('question-type');
            var questionID = $('.question').data('question-id');
            var value = null;

            // get the information for a question with type core
            if(questionType === 'core'){
                $('#core-question input').on('change', function() {
                    var value = $('input[name=question]:checked', '#core-question').val();
                    saveAnswer(questionID, value);
                });
            }
            if(questionType === 'dichotomous'){
                $('.dichotomous-answer').on('click', function(){
                    value = $(this).data('value');
                    console.log(value);
                    saveAnswer(questionID, value);
                });
            }
            if(questionType === 'slider'){
                // slider page
                // if the slider has been touched the class is set to selected
                // if the slider is released the button for next question will come up
                var slideEl = $('input[type="range"]');
                if(slideEl.length !== 0) {
                    var slider = slideEl.rangeslider({
                        polyfill: false,
                        rangeClass: 'rangeslider',

                        // Callback function
                        onSlide: function(position, value) {
                            $('.rangeslider').addClass('selected');
                        },

                        // Callback function
                        onSlideEnd: function(position, value) {
                            value = Math.round(value);
                            saveAnswer(questionID, value);
                            showButtons($('.rangeslider'));
                        }
                    });
                }
            }
        }

        function saveAnswer(questionID, value) {
            $.ajax({
                 type: "POST",
                 url: config.baseUrl + '/question/answer',
                 data: {
                    'questionID' : questionID,
                    'value' : value,
                    _token: CSRF_TOKEN
                 },
                 dataType: 'JSON',
                 succes: function(data) {
                    //
                 }
            });
            console.log(questionID);
        }
    });
})(jQuery);